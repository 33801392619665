export const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_FIREBASE_APPID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  // apiKey: "AIzaSyAJ6Jg9HhW6lN4hEv6OUZ_0x0eyL2LHgRQ",
  // authDomain: "v-leagues.firebaseapp.com",
  // projectId: "v-leagues",
  // storageBucket: "v-leagues.appspot.com",
  // messagingSenderId: "883654767893",
  // appId: "1:883654767893:web:a3f78c749302005ce2251c",
  // measurementId: "G-4KBGV5R57T"

  apiKey: "AIzaSyAfXgUNsffUSJErnlzQwoVyBwtk4BzJ3K4",
  authDomain: "convenient-way.firebaseapp.com",
  projectId: "convenient-way",
  storageBucket: "convenient-way.appspot.com",
  messagingSenderId: "465111730158",
  appId: "1:465111730158:web:c4e43d7fee4bbc5437b0ff",
  measurementId: "G-N3GS2FDY3M"
};
export const vapidKey = 'BCcq6t2HkYMyHXKtMtlBGDXeVAYrQoeWJ9HtbQPFnCxaxin55EGoiKLQFr2V-MHiJ3ce2gmAnGMMoRcYW8axhMw'
export const PackageStatusColor = {
  waiting: 'default',
  approved: 'success',
  reject: 'error',
  selected: 'success',
  pickupSuccess: 'success',
  pickupFailed: 'error',
  deliveredSuccess: 'success',
  deliveredFailed: 'error',
  success: 'success',
  refundToWarehouseSuccess: 'success',
  refundToWarehouseFailed: 'error',
  deliverCancel: 'error',
  senderCancel: 'error',
  expired: 'warning',
  deliverPickup: 'default'
}
export const PackageStatus = {
  waiting: 'WAITING',
  approved: 'APPROVED',
  reject: 'REJECT',
  selected: 'SELECTED',
  pickupSuccess: 'PICKUP_SUCCESS',
  pickupFailed: 'PICKUP_FAILED',
  deliveredSuccess: 'DELIVERED_SUCCESS',
  deliveredFailed: 'DELIVERED_FAILED',
  success: 'SUCCESS',
  refundToWarehouseSuccess: 'REFUND_TO_WAREHOUSE_SUCCESS',
  refundToWarehouseFailed: 'REFUND_TO_WAREHOUSE_FAILED',
  deliverCancel: 'DELIVER_CANCEL',
  senderCancel: 'SENDER_CANCEL',
  expired: 'EXPIRED'
}
export const transactionType = {
  recharge: 'RECHARGE',
  pickup: 'PICKUP',
  deliveredSuccess: 'DELIVERED_SUCCESS'
}
export const switchTransactionType = (key) => {
  switch (key) {
    case transactionType.recharge:
      return 'Nạp tiền';
    case transactionType.pickup:
      return 'Nhận đơn';
    case transactionType.deliveredSuccess:
      return 'Giao hàng';
    default:
      return '*'
  }
}
export const transactionStatus = {
  success: 'SUCCESS',
  accomplished: 'ACCOMPLISHED'
}
export const switchTransactionStatus = (key) => {
  switch (key) {
    case transactionStatus.success:
      return 'Thành công';
    case transactionStatus.accomplished:
      return 'Hoàn thành';
    default:
      return '*'
  }
}
export const userRole = {
  admin: 'ADMIN',
  adminBalance: 'ADMIN_BALANCE',
  user: 'USER',

}
export const userType = {
  deliver: 'DELIVER',
  sender: 'SENDER',
  receiver: 'RECEIVER'
}
export const switchPackageStatus = (key) => {
  switch (key) {
    case PackageStatus.waiting:
      return 'Chờ xác nhận'
    case PackageStatus.approved:
      return 'Đã xác nhận'
    case PackageStatus.reject:
      return 'Bị từ chối'
    case PackageStatus.selected:
      return 'Đang lấy hàng'
    case PackageStatus.pickupSuccess:
      return 'Đã lấy hàng'
    case PackageStatus.pickupFailed:
      return 'Lấy hàng thất bại'
    case PackageStatus.success:
      return 'Thành công'
    case PackageStatus.refundToWarehouseSuccess:
      return 'Hoàn kho thành công'
    case PackageStatus.refundToWarehouseFailed:
      return 'Hoàn kho thất bại'
    case PackageStatus.senderCancel:
      return 'Người gửi hủy đơn'
    case PackageStatus.deliverCancel:
      return 'Người giao hủy đơn'
    default:
      return 'Hết hạn'
  }
}
export const switchGender = (key) => {
  switch (key) {
    case 'MALE':
      return 'Nam'
    case 'FEMALE':
      return 'Nữ'
    default:
      return 'Khác';
  }
}
export const switchUserRole = (key) => {
  switch (key) {
    case 'USER':
      return 'Người dùng'
    default:
      return 'Quản lí';
  }
}
export const DOMAIN = 'https://ship-convenient.azurewebsites.net'
export const ACCESSTOKEN = 'accessToken'
export const FIREBASETOKEN = 'firebaseToken'
export const userStatus = {
  active: 'ACTIVE',
  inActive: 'INACTIVE',
  noRoute: 'NO_ROUTE'
}
export const userStatusName = {
  ACTIVE: 'Hoạt động',
  INACTIVE: 'Không hoạt động',
  NO_ROUTE: 'Chưa có lộ trình'
}
export const switchUserStatus = (key) => {
  switch (key) {
    case userStatus.active:
      return 'Hoạt động';
    case userStatus.noRoute:
      return 'Chưa có lộ trình'
    default:
      return 'Không hoạt động';
  }
}
export const configStatus = {

}
export const configName = {
  PROFIT_PERCENTAGE: 'Tỉ suất lợi nhuận',
  PROFIT_PERCENTAGE_REFUND: 'Tỉ suất hoàn trả',
  MINIMUM_DISTANCE: 'Khoảng cách đơn hàng tối thiểu',
  MAX_PICKUP_SAME_TIME: 'Số lượng đơn hàng nhận tối đa',
  MAX_ROUTE_CREATE: 'Số tuyến đường tối đa',
  DEFAULT_BALANCE_NEW_ACCOUNT: 'Số dư mặc định',
  MAX_SUGGEST_COMBO: 'Số lượng đơn hàng gợi ý tối đa',
  MAX_CANCEL_IN_DAY: 'Số lượt hủy đơn tối đa trong ngày'
}
export const defaultId = '00000000-0000-0000-0000-000000000000'
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDyiGQYQgykVRVpeqCWXtqn5l4At9vGp04'
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoibmd1eWVuaHVuZzA5MDgiLCJhIjoiY2xlNnEyN3B6MHBtZDNvcWkyYzBsZGs4eSJ9.EZUEuD82Z28rm8ezKDRltg'
export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = 'pk.eyJ1Ijoibmd1eWVuaHVuZzA5MDgiLCJhIjoiY2xlNnEyN3B6MHBtZDNvcWkyYzBsZGs4eSJ9.EZUEuD82Z28rm8ezKDRltg';

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});

export const requiredText = <span style={{ color: 'red' }}>*</span>
