import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: {
    data: [],
    paginated: {
      pageIndex: 0,
      pageSize: 0,
      totalCount: 0,
      totalPage: 0
    }
  },
  hasMore: true,
  userList: {
    data: [],
    paginated: {
      pageIndex: 0,
      pageSize: 0,
      totalCount: 0,
      totalPage: 0
    }
  },
  userTransactionList: {
    data: [],
    paginated: {
      pageIndex: 0,
      pageSize: 0,
      totalCount: 0,
      totalPage: 0
    }
  },
  currentUser: {},
  registerUser: null,
  index: 0,
  step: 0,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getUserList(state, action) {
      state.isLoading = false;
      state.error = false;
      state.userList = action.payload;
    },
    getUserTransactionList(state, action) {
      state.isLoading = false;
      state.error = false;
      state.userTransactionList = action.payload;
    },

    getUserDetail(state, action) {
      state.isLoading = false;
      state.error = false;
      state.currentUser = action.payload;
    },


    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
      const newNotifications = [];
      if (state.notifications.data.length !== 0) {
        action.payload.data.forEach((notification, index) => {
          if (!state.notifications.data.find(stateNotification => stateNotification.id === notification.id)) {
            newNotifications = [...newNotifications, notification]
          }
        })
        state.notifications.data = [].concat(state.notifications.data, newNotifications)
      }
      else {
        state.notifications = action.payload
      }
    },

    getNotificationsSuccessNew(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    getRegisterUser(state, action) {
      state.isLoading = false;
      state.registerUser = action.payload
    },
    noHasMore(state) {
      state.hasMore = false;
    },
    getMoreNotifications(state) {
      const setIndex = state.step + 1;
      state.index = setIndex;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser, getMoreNotifications } = slice.actions;

// ----------------------------------------------------------------------
export function getRegisterUser(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getRegisterUser(values));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}
export function getNotifications(accountId, pageIndex) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1.0/notifications?accountId=${accountId}&pageIndex=${pageIndex}`)
      const results = response.data.data.length;
      const { totalCount } = response.data.paginated;
      console.log('response', response);
      console.log('result', results);
      console.log('totalCount', totalCount);
      // dispatch(slice.actions.getNotificationsSuccess(response.data));
      if (results >= totalCount) {
        dispatch(slice.actions.noHasMore());
      }
      if (pageIndex !== 0) {
        dispatch(slice.actions.getNotificationsSuccess(response.data));
      } else {
        dispatch(slice.actions.getNotificationsSuccessNew(response.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getUserList(userName, status, role, pageIndex, pageSize) {


  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1.0/accounts?userName=${userName || ''}&status=${status || ''}&role=${role}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
      console.log('responese', response);
      dispatch(slice.actions.getUserList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
  // if (userName && status) {
  //   return async (dispatch) => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.get(`/api/v1.0/accounts?userName=${userName}&status=${status}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
  //       console.log('responese', response);
  //       dispatch(slice.actions.getUserList(response.data));
  //     } catch (error) {
  //       dispatch(slice.actions.hasError(error));
  //     }
  //   };
  // }
  // if (userName) {
  //   return async (dispatch) => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.get(`/api/v1.0/accounts?userName=${userName}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
  //       console.log('responese', response);
  //       dispatch(slice.actions.getUserList(response.data));
  //     } catch (error) {
  //       dispatch(slice.actions.hasError(error));
  //     }
  //   };
  // }
  // if (status) {
  //   return async (dispatch) => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.get(`/api/v1.0/accounts?status=${status}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
  //       console.log('responese', response);
  //       dispatch(slice.actions.getUserList(response.data));
  //     } catch (error) {
  //       dispatch(slice.actions.hasError(error));
  //     }
  //   };
  // }
  // return async (dispatch) => {
  //   dispatch(slice.actions.startLoading());
  //   try {
  //     const response = await axios.get(`/api/v1.0/accounts?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  //     console.log('responese', response);
  //     dispatch(slice.actions.getUserList(response.data));
  //   } catch (error) {
  //     dispatch(slice.actions.hasError(error));
  //   }
  // };
}
export function getUserDetail(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1.0/accounts/${userId}`);
      console.log('responese', response);

      if (response.status === 200) {
        dispatch(slice.actions.getUserDetail(response.data.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getUserTransaction(accountId, from, to, pageIndex, pageSize) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1.0/transactions?accountId=${accountId || ''}&from=${from || ''}&to=${to || ''}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
      console.log('responese', response);
      dispatch(slice.actions.getUserTransactionList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

























export function registerUser(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.registerUser(values));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function getUserList() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/user/manage-users');
//       dispatch(slice.actions.getUserListSuccess(response.data.users));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
