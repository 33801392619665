import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { async } from '@firebase/util';
import { PackageStatus } from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    configs: [],
};

const slice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getConfigs(state, action) {
            state.isLoading = false;
            state.error = false;
            state.configs = action.payload;
        },
        editConfig(state, action) {
            state.isLoading = false;
            state.error = false;
            const newConfigs = state.configs.map(config => {
                if (config.id === action.payload.id) {
                    return action.payload
                }
                return config
            })
            state.configs = newConfigs
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getConfigs() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/v1.0/configs`);
            console.log(response);
            dispatch(slice.actions.getConfigs(response.data.data))

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}
export function editConfig(values, callback) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`/api/v1.0/configs`, values);
            dispatch(slice.actions.editConfig(response.data.data))
            callback({ success: response.data.success, message: response.data.message })


        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback(error.response.data)
        }
    }
}



