import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useRef, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Skeleton,
  Grid,
  Stack
} from '@mui/material';
// utils
import mockData from '../../utils/mock-data';
import { fToNow } from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { getMoreNotifications, getNotifications } from 'src/redux/slices/user';
import _ from 'lodash';
import { PATH_DASHBOARD } from 'src/routes/paths';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

// ----------------------------------------------------------------------

const TITLES = [
  'Your order is placed',
  'Sylvan King',
  'You have new message',
  'You have new mail',
  'Delivery processing'
];

const DESCRIPTIONS = [
  'waiting for shipping',
  'answered to your comment on the Minimal',
  '5 unread messages',
  'sent from Guido Padberg',
  'Your order is being shipped'
];

const TYPES = ['order_placed', 'friend_interactive', 'chat_message', 'mail', 'order_shipped'];

const AVATARS = [null, mockData.image.avatar(2), null, null, null];

const UNREADS = [true, true, false, false, false];

const MOCK_NOTIFICATIONS = [...Array(5)].map((_, index) => ({
  id: mockData.id(index),
  title: TITLES[index],
  description: DESCRIPTIONS[index],
  avatar: AVATARS[index],
  type: TYPES[index],
  createdAt: mockData.time(index),
  isUnRead: UNREADS[index]
}));

// ----------------------------------------------------------------------
const handleContent = (content) => {
  const searchTerm = 'với:'
  const indexOfLast = content.lastIndexOf(searchTerm)
  const newContent = content.slice(0, indexOfLast - 1)
  const packageId = content.slice(indexOfLast + 1)
  return newContent
}
function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        <br /> {handleContent(notification.content)}
      </Typography>
    </Typography>
  );

  if (notification.typeOfNotification === 'APPROVED') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.typeOfNotification === 'DELIVERY') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.typeOfNotification === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.typeOfNotification === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};
const getPackageId = (notification) => {
  const { content } = notification
  const searchTerm = 'với:'
  const indexOfLast = content.lastIndexOf(searchTerm)
  const packageId = content.slice(indexOfLast + searchTerm.length)
  return packageId
}
function NotificationItem({ notification }) {
  console.log('notify', notification);
  const { avatar, title } = renderContent(notification);
  const packageId = getPackageId(notification)
  return (
    <ListItemButton
      to={`${PATH_DASHBOARD.order.preview}/${packageId}`}
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(moment.utc(notification.createdAt).utcOffset(7))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
const SkeletonLoad = (
  <Grid container spacing={3} sx={{ mt: 2 }}>
    {[...Array(4)].map((_, index) => (
      <Grid item xs={12} key={index} fullWidth>
        {/* <Skeleton variant="rectangular" width="100%" sx={{ height: 200, borderRadius: 2 }} /> */}
        <Box sx={{
          display: 'flex', py: 1.5,
          px: 2.5,
          mt: '1px',
        }}>
          <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
          <ListItemText
            primary={<Skeleton variant="text" />}
            secondary={
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled'
                }}
              >
                <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
                <Skeleton variant="text" width={100} />
              </Typography>
            }
          />
          {/* <Box sx={{ display: 'flex', flexDirection: 'cou' alignItems: 'center', py: 2, px: 2.5 }} fullWidth >
            <Typography variant="h6"> <Skeleton variant="text" /></Typography>
            <Typography variant="caption"> <Skeleton variant="text" sx={{ mt: 0.5 }} /></Typography>

          </Box> */}
        </Box>
      </Grid>
    ))}
  </Grid>
);
export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { user } = useAuth()
  const { notifications, hasMore, index, step } = useSelector(state => state.user)
  const onScroll = useCallback(() => dispatch(getMoreNotifications()), [dispatch]);

  // const [notifications, setNotifications] = useState(MOCK_NOTIFICATIONS);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const totalUnRead = notifications.data.filter((item) => item.isUnRead === true).length;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
  };

  useEffect(() => {
    dispatch(getNotifications(user.id, step, rowsPerPage))
  }, [dispatch, user, step])
  useEffect(() => {
    const getNotificationList = setInterval(() => {
      dispatch(getNotifications(user.id, step, rowsPerPage))
    }, 5000)
    return () => clearInterval(getNotificationList)
  }, [dispatch, user, step])
  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Thông báo</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Bạn có {totalUnRead} thông báo chưa xem
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar
          sx={{ height: { xs: 340, sm: 64 * 8 } }}
        // sx={{ height: { xs: 340, sm: 'auto' } }}
        >
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Mới nhất
              </ListSubheader>
            }
          >

            {notifications.data.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Trước đó
              </ListSubheader>
            }
          >
            <InfiniteScroll
              next={onScroll}
              hasMore={hasMore}
              loader={SkeletonLoad}
              dataLength={notifications.data.length}
              style={{ overflow: 'inherit' }}
            >
              {notifications.data.slice(2).map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </InfiniteScroll>

          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            Xem tất cả
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
