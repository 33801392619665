import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { async } from '@firebase/util';
import { PackageStatus } from 'src/config';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    feedbackList: {
        data: [],
        paginated: {
            pageIndex: 0,
            pageSize: 0,
            totalCount: 0,
            totalPage: 0
        }
    },
    currentFeedback: {},
    feedbackRating: {}
};

const slice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getFeedbackList(state, action) {
            state.isLoading = false;
            state.error = false;
            state.feedbackList = action.payload;
        },
        editFeedback(state, action) {
            state.isLoading = false;
            state.error = false;
            state.currentFeedback = { ...state.currentFeedback, ...action.payload }
        },
        getFeedbackDetail(state, action) {
            state.isLoading = false;
            state.error = false;
            state.currentFeedback = action.payload;
        },
        createFeedback(state, action) {
            state.isLoading = false;
            state.error = false;
            state.currentFeedback = { ...state.currentFeedback, ...action.payload }
        },
        getRating(state, action) {
            state.isLoading = false;
            state.error = false;
            state.feedbackRating = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteOrder } = slice.actions;

// ----------------------------------------------------------------------

export function getFeedbackRating(accountId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/v1.0/feedbacks/rating/${accountId}`);
            dispatch(slice.actions.getRating(response.data.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}
export function createFeedback(values) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const response = await axios.post(`/api/v1.0/feedbacks`, values);
        dispatch(slice.actions.createFeedback(response.data.data))
    }
}
export function editFeedback(values) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const response = await axios.put(`/api/v1.0/feedbacks`, values);
        dispatch(slice.actions.editFeedback(response.data.data))

    }
}
export function getFeedbackDetail(packageId, feedbackFor) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/v1.0/feedbacks?packageId=${packageId}&feedbackFor=${feedbackFor}`);
            dispatch(slice.actions.getFeedbackDetail(response.data.data[0]))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// export function createOrder(values, callback) {
//     return async (dispatch) => {
//         dispatch(slice.actions.startLoading());
//         try {
//             const response = await axios.post(`/api/v1.0/packages`, values);
//             if (response.data.status === 200) {
//                 dispatch(slice.actions.createOrder(response.data.data));
//                 callback({ IsError: response.data.success })
//             }
//         } catch (error) {
//             callback(error.response.data)
//             dispatch(slice.actions.hasError(error));
//         }
//     }
// }

// ----------------------------------------------------------------------

